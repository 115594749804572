<template>
    <router-view name="Screen" v-slot="{ Component }">
        <Transition name="slide" mode="out-in" appear>
            <component :key="$route.fullPath" :is="Component" />
        </Transition>
    </router-view>
    <router-view name="Rodape" v-slot="{ Component }">
        <Transition name="slide" mode="out-in" appear>
            <component :key="$route.fullPath" :is="Component" />
        </Transition>
    </router-view>
</template>

<script>
export default {
    name: 'App',
}
</script>

<style>
#app{
    overflow-x: hidden;
}
.slide-enter-from {
    transition: transform 400ms;
    transform: translateX(100vw);
}

.slide-enter-active {
    transition: transform 400ms;
    transform: translateX(100vw);
}

.slide-enter-to {
    transition: transform 400ms;
    transform: translateX(0vw);
}

.slide-leave-from {
    transition: transform 400ms;
    transform: translateX(0vw);
}

.slide-leave-active {
    transition: transform 400ms;
    transform: translateX(0vw);
}

.slide-leave-to {
    transition: transform 400ms;
    transform: translateX(-100vw);
}
</style>
